/**
 * Email validation regex that enforces:
 * 1. Must have @ symbol
 * 2. Must have at least one dot after @ (proper domain format)
 * 3. Must end with a valid TLD (2 or more letters)
 * 4. Supports subdomains (e.g. sub.domain.com)
 *
 * The regex validates these parts:
 * Local part (before @): Can contain any characters except <>()[]\\,;:s@" and dots between parts
 * Domain part (after @): Must be a valid domain with at least one dot and proper TLD
 *
 * NOTE: This regex DOES NOT support validation for non-ASCII characters
 *
 * Capture groups:
 * 1. Full local part
 * 2. Dot-atom part of local part OR quoted part
 * 3. Last dot match in local part (if any)
 * 4. Quoted part (if used instead of dot-atom)
 * 5. Domain part
 */
export const getEmailRegex = (): RegExp => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/g;
};

export const isEmail = (emailAddress: string): boolean => {
  return getEmailRegex().test(emailAddress.trim());
};

export const isLink = (link: string): boolean => {
  /* Regex from https://stackoverflow.com/a/3809435/7895874 and (http://regexr.com/6ob6d) */
  const linkFormatRegex = new RegExp(
    /https?:\/\/(www\.)?\b([a-zA-Z0-9-]){2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*$)/g,
  );

  if (link.includes('..') || link.includes('-.') || link.includes('.-')) {
    return false;
  }

  if (link.split('//').length > 2) {
    return false;
  }

  return linkFormatRegex.test(link.trim());
};

export const isContact = (contact: string): boolean => {
  /* Regex refrence: https://www.codegrepper.com/code-examples/javascript/regex+to+validate+a+phone+number
   * Modified and sample test : https://regexr.com/6nt0u
   */
  const contactFormatRegex: RegExp = new RegExp(/^(\+)(\d{9,13})$/g);
  return contactFormatRegex.test(contact.trim());
};

export const isDomain = (domain: string): boolean => {
  // https://stackoverflow.com/a/26987741/7895874
  // sample regex test: https://regex101.com/r/tcJ8oX/1
  // https://somedomain.com?params=something&arstarst=wqpqwfp
  const domainRegex = new RegExp(
    /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/g,
  );

  if (domain.includes('_')) {
    return false;
  }

  if (domain.split('.').length === 1) {
    return false;
  }

  if (domain.split('.')[domain.split.length - 1]) {
    const tldIsNumber = /\d/.test(domain);
    if (tldIsNumber) {
      return false;
    }
  }

  if (domain.charAt(0) === '-') {
    return false;
  }

  if (domain.includes('.-') || domain.includes('-.')) {
    return false;
  }

  return domainRegex.test(domain);
};

const hasValidSubdomain = (
  domainInEmail: string,
  domainToCheckAgainst: string,
): boolean => {
  /*
    Email domain splitting will return TWO strings
    // ['subdomain.', '']  < valid
    // ['', '']            < invalid (domain exact match) // will not reach here in this function
    // ['', '.my']         < invalid (trailing TLD)
    // ['xxx.mail.com']    < invalid (complete mismatch)

    VALID SUBDOMAIN should fit below criterias after splitting:
    First string should always be anything with trailing dot (eg: 'm.', 'asdf.')
    Second string should always be empty ''
  */

  const emailSubdomain = domainInEmail.split(domainToCheckAgainst)[0]; // ['subdomain.', '']
  const trailingString = domainInEmail.split(domainToCheckAgainst)[1];

  if (trailingString.length > 0) {
    return false;
  }

  if (emailSubdomain.length === 1) {
    // subdomain length has to be 2 or more (including the trailing '.')
    return false;
  }

  if (
    emailSubdomain.length >= 2 &&
    emailSubdomain.charAt(emailSubdomain.length - 1) !== '.'
  ) {
    return false;
  }

  return true;
};

export const validateEmailDomain = (
  email: string,
  allowedDomains: string[],
): boolean => {
  if (allowedDomains && allowedDomains.length > 0) {
    const emailDomain = email.split('@')[1].toLowerCase(); // capture everything after '@'
    const matchedDomain = allowedDomains.find(
      (domain) => domain.toLowerCase() === emailDomain,
    );

    // find position of target domain to crosscheck against.
    if (matchedDomain) {
      const domainIndex = allowedDomains.indexOf(matchedDomain);
      return hasValidSubdomain(emailDomain, allowedDomains[domainIndex]);
    } else {
      const partialMatches = allowedDomains.filter((domain) =>
        emailDomain.includes(domain.toLowerCase()),
      );

      if (partialMatches.length === 0) {
        return false;
      }

      if (partialMatches.length === 1) {
        const domainIndex = allowedDomains.findIndex(
          (domain) => domain.toLowerCase() === partialMatches[0],
        );
        return hasValidSubdomain(emailDomain, allowedDomains[domainIndex]);
      }

      const targetDomain = partialMatches.find((partiallyMatchedDomain) =>
        // ['snappymob.com', 'mob.com'] partial match in 'user@dev.snappymob.com'
        // 'user@dev.snappymob.com' should check against 'snappymob.com' and not the other partial matches
        hasValidSubdomain(emailDomain, partiallyMatchedDomain),
      );

      if (targetDomain) {
        const domainIndex = allowedDomains.findIndex(
          (domain) => domain.toLowerCase() === targetDomain.toLowerCase(),
        );
        return hasValidSubdomain(emailDomain, allowedDomains[domainIndex]);
      } else {
        return false;
      }
    }
  } else {
    return true;
  }
};
